import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/Layout/layout"

import Gallery from "../components/Gallery/gallery"

const GalleryPage = () => {
  const data = useStaticQuery(query)

  return (
    <Layout seo={data.strapiHomepage.seo}>
      <div className="uk-container">
        <Gallery albums={data.allStrapiGallery.edges} />
      </div>
    </Layout>
  )
}

const query = graphql`
  query {
    strapiHomepage {
      seo {
        metaTitle
        metaDescription
      }
    }
    allStrapiGallery(sort:{fields: publishedAt, order: DESC}) {
      edges {
        node {
          strapiId
          slug
          title
          link
          titleImage {
            localFile{
              childImageSharp {
                fixed(width: 500, height: 400) {
                  src
                }
              }
            }
          }
          images{
            url
          }
        }
      }
    }
  }
`

export default GalleryPage