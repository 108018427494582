import React from "react"
import Img from "gatsby-image"

const Album = ({ album }) => {
  const url = album.node.link ? album.node.link : `/gallery/${album.node.slug}`
  const target = album.node.link ? "_blank" : ""
  return (
    <div>
      <div className="uk-card uk-card-default uk-card-body">
        <a
          title={album.node.title}
          className="uk-link-reset"
          href={url}
          target={target}
        >
          <Img
            fixed={album.node.titleImage.localFile.childImageSharp.fixed}
            imgStyle={{ position: "static" }}
          />
          <p>{album.node.title}</p>
        </a>
      </div>
    </div>
  )
}

export default Album