import React from "react"
import Album from "../Album/album"

const Gallery = ({ albums }) => {
  return (
    <>
      <h4 className="uk-heading-line uk-text-bold"><span>Galeria</span></h4>
      <div className="uk-grid-column-small uk-grid-row-large uk-child-width-1-3@s uk-text-center uk-grid">
      {albums.map((album, i) => {
          return (
            <Album
              album={album}
              key={`album${album.node.slug}`}
            />
          )
        })}
      </div>
    </>
  )
}

export default Gallery